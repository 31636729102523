import './App.css';
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import PreLoading from './components/PreLoading';
import HomePage from './pages/HomePage';
import Afaaq from './pages/Afaaq'; // Import the Afaaq component

function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  // Determine the current language
  const currentLanguage = t('language.direction');

  // Define font families and weights for each language
  const arabicFontFamily = 'Hacen-Maghreb';
  const arabicFontWeight = 400;
  const englishFontFamily = 'Darker Grotesque';
  const englishFontWeight = 600;

  // Apply font family and weight based on the language
  const fontFamily = currentLanguage === 'rtl' ? arabicFontFamily : englishFontFamily;
  const fontWeight = currentLanguage === 'rtl' ? arabicFontWeight : englishFontWeight;

  useEffect(() => {
    // Simulate loading process (e.g., data fetching, resource loading)
    setTimeout(() => {
      setLoading(false); // Set loading state to false after 2 seconds (simulated loading time)
    }, 2000);
  }, []);

  return (
    <div className="App" style={{ fontFamily: `${fontFamily}, sans-serif`, fontWeight }}>
     <Router basename="/">
        {loading ? (
          <PreLoading />
        ) : (
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/afaaq" element={<Afaaq />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;