import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const AboutUs = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section
      id="about"
      className="relative  w-full bg-cover bg-center overflow-hidden"
      style={{ backgroundImage: `url('../assets/img/about-bg.webp')` }}
    >
      {/* Container for all content */}
      <div className="container mx-auto px-4 md:px-6 lg:px-8 h-screen">
        <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
          {/* Right side - Content */}
          <div className="flex flex-col justify-start  pt-8 lg:pt-16 h-full">
            {/* Logo/Title */}
            <div className="mb-16 lg:mb-24 flex flex-col " data-aos="fade-left">
              <img
                src={t("About.gradientRhombic")}
                alt="Wetaan Logo"
                className="w-12 h-12 mb-2"
              />
              <h2 className="text-[#F3D600] text-xl font-medium mt-4">
                عن وتان
              </h2>
            </div>

            {/* Text content */}
            <div className="text-right max-w-xl w-full">
              <h1
                data-aos="fade-left"
                data-aos-delay="200"
                className="text-3xl sm:text-4xl lg:text-5xl font-bold text-[#7DD3FC] mb-6 lg:mb-8"
              >
                ثبات ممتد ونمو مستمر
              </h1>

              <p
                data-aos="fade-left"
                data-aos-delay="400"
                className="text-white text-base sm:text-lg lg:text-xl leading-relaxed"
                style={{ direction: "rtl" }}
              >
                فريقنا شغوف بالإبداع، بخبرة تتجاوز 15 عامًا، في كل مشروع نحرص
                على ترك أثر يُلهم عملاءنا نحو التميز.
              </p>
            </div>

            {/* Mobile map - Only visible on small screens */}
            <div className="lg:hidden w-full mt-12">
              <img
                src={t("About.map")}
                alt="Saudi Arabia Map"
                className="w-full max-w-md mx-auto"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
          </div>
          {/* Left side - Map */}
          <div className="hidden lg:flex items-center justify-center">
            <img
              src={t("About.map")}
              alt="Saudi Arabia Map"
              className="w-full max-w-lg object-contain"
              data-aos="fade-right"
              data-aos-delay="300"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
