import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  const { t } = useTranslation();
  const animationDelay = ["600", "900", "1200"];

  useEffect(() => {
    // Initialize AOS with mobile-friendly settings
    AOS.init({
      once: true, // This will make animations occur only once
      disable: "mobile", // Disable animations on mobile if they cause issues
      duration: 750, // Shorter duration for better mobile performance
      offset: 100, // Smaller offset for mobile
    });
  }, []);

  // Safely handle translation of services array
  const getServices = () => {
    try {
      return t("Services2.Services", { returnObjects: true }) || [];
    } catch (error) {
      console.error("Error loading services:", error);
      return [];
    }
  };

  return (
    <section
      id="services"
      className="min-h-screen w-full px-4 sm:px-8 py-8 sm:py-12 bg-[#020055]"
    >
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="container mx-auto px-4 sm:px-0 max-w-7xl"
      >
        {/* Header Section */}
        <div className="text-center mb-8 sm:mb-12">
          <h5 className="text-white mb-2 sm:mb-3">
            {t("Services2.title", "")}
          </h5>
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-bold mb-4 mt-5">
            {t("Services2.description.0", "")}
            <br />
          </h1>
          <h2 className="text-lg sm:text-xl  md:text-2xl  text-white mb-8  sm:px-8  md:px-16 lg:px-32">
            {t("Services2.description.1", "")}
          </h2>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
          {getServices().map((service, index) => (
            <div
              key={index}
              data-aos="fade-up"
              data-aos-delay={animationDelay[index]}
              className="flex flex-col bg-[#020055] text-white border border-[#ffffff33] rounded-lg shadow-lg hover:border-[#ffffff66] transition-all duration-300 overflow-hidden"
            >
              {/* Card Content */}
              <div className="p-4 sm:p-6 flex flex-col h-full">
                {/* Icon with error handling */}
                <div className="w-14 h-14 mb-4">
                  <img
                    className="w-full h-full object-contain"
                    src={service.icon}
                    alt={service.title}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../assets/img/default-icon.webp"; // Fallback image
                    }}
                  />
                </div>

                {/* Text Content */}
                <div className="flex flex-col flex-grow">
                  <h5 className="text-lg sm:text-xl md:text-2xl font-bold mb-3 sm:mb-4 text-white">
                    {service.title || ""}
                  </h5>
                  <p className="text-gray-300 text-sm sm:text-base font-normal flex-grow">
                    {service.text || ""}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
