import React from 'react';
import { useTranslation } from 'react-i18next';

const AfaaqHero = () => {
  const { t } = useTranslation();

  const handleContactClick = () => {
    const contactSection = document.getElementById('AfaaqContactUs');
    if (contactSection) {
      const headerHeight = document.querySelector('header')?.offsetHeight || 0;
      const offset = 80;
      const elementPosition = contactSection.getBoundingClientRect().top + window.pageYOffset;
      
      window.scrollTo({
        top: elementPosition - headerHeight - offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="relative min-h-screen">
      <div className="container mx-auto px-4 py-12 md:py-24 flex flex-col md:flex-row items-start">
        {/* Hero Image - Left side */}
        <div 
          className="w-full lg:w-1/2 py-12 sm:py-24 lg:py-40 lg:absolute lg:left-0 lg:top-0 lg:bottom-0 animate-fadeIn"
        >
          <img 
            src={t('afaaq.heroImagePath')} 
            alt={t('afaaq.heroImageAlt')} 
            className="w-full object-contain md:object-cover"
            loading="eager"
            width="800"
            height="800"
            decoding="async"
          />
        </div>

        {/* Content overlay */}
        <div 
          className="md:w-1/2 md:ml-auto relative z-10 animate-fadeIn"
        >
          <div className="w-full py-8 sm:py-16 lg:py-48 text-right">
            <div className="mb-6 animate-fadeInUp">
              <img 
                src={t('afaaq.logoPath')} 
                alt={t('afaaq.logoAlt')} 
                className="h-16 md:h-20 inline-block"
                width="160"
                height="80"
                loading="eager"
                decoding="async"
              />
            </div>

            <h2 
              className="text-2xl sm:text-3xl lg:text-5xl font-bold mb-3 sm:mb-4 lg:mb-6 text-lightBlue leading-tight animate-fadeInUp delay-200"
            >
              {t('afaaq.subtitle')}
            </h2>

            <p 
              className="text-base sm:text-lg lg:text-xl mb-6 sm:mb-8 lg:mb-10 text-white/90 leading-relaxed animate-fadeInUp delay-300"
            >
              {t('afaaq.description')}
            </p>

            <div 
              className="flex flex-col sm:flex-row gap-4 sm:gap-6 items-stretch sm:items-center animate-fadeInUp delay-400"
            >
              <button 
                onClick={handleContactClick}
                className="w-full sm:w-auto bg-lightBlue text-white px-6 py-2.5 rounded-full 
                  hover:bg-opacity-90 transition-all duration-300 text-base sm:text-lg 
                  transform hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 
                  focus:ring-lightBlue focus:ring-offset-2 focus:ring-offset-primary"
                aria-label={t('afaaq.contactButton')}
              >
                {t('afaaq.contactButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfaaqHero;