import { React, useState, useEffect } from "react";
import { useForm } from "@formspree/react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./alert/custom-toast-styles.css";

const ContactUs = () => {
  const { t } = useTranslation();

  const initialState = {
    name: "",
    email: "",
    message: "",
    companyName: "",
    companyStage: "",
    phone: "",
  };
  const [{ name, email, message, companyName, companyStage, phone }, setState] =
    useState(initialState);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [state, handleSubmit] = useForm("mjvnabrp");

  const notify = () => toast.success(t("alert.text"));
  const notifyError = () => toast.error(t("alert.error"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    console.log("Clearing form data...");
    setState({
      name: "",
      email: "",
      message: "",
      companyName: "",
      companyStage: "",
      phone: "",
    });
    console.log({ name, email, message, companyName, companyStage, phone }); // Log updated data within the same useEffect
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (state.succeeded) {
      setSuccess("Thank you for going!");
      notify();
      clearState();
      setState(initialState);
    }
    if (state.succeeded === "false") {
      notifyError();
      setError("There was an error");
    }
  }, [state.succeeded]);

  // eslint-disable-next-line
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section id="contact" className="h-auto pt-4 sm:px-16 px-6">
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        className="container mx-auto p-6 sm:p-10 rounded-xl grid grid-cols-1 md:grid-cols-2 gap-4"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
      >
        <div className="pt-16 pb-6 sm:px-8 md:px-4 text-white md:col-span-1">
          <h1 className="pt-6 px-8 sm:px-16 font-bold">
            {t("Contact.paragraph1")}
          </h1>
        </div>

        <div className="container px-4 sm:px-8 md:px-12 py-6 md:col-span-1">
          <form
            name="sentMessage"
            className="w-full px-6 md:items-end"
            validate
            onSubmit={handleSubmit}
          >
            {t("Contact.inputs").map((item, index) => (
              <div key={index} className="mb-8 flex flex-col">
                <label htmlFor={item.label} className="flex text-white mb-2">
                  {item.label}
                </label>
                {item.id === "CompanyStage" ? (
                  <select
                    style={{
                      padding: "5px",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    id="companyStage"
                    value={companyStage}
                    name="companyStage"
                    className="form-control rounded input"
                    required
                    onChange={handleChange}
                    onFocus={(e) =>
                      (e.target.style.backgroundColor = "#020055")
                    }
                    onBlur={(e) =>
                      (e.target.style.backgroundColor =
                        "rgba(255, 255, 255, 0.1)")
                    } // Restore original background color on blur
                  >
                    <option value="" disabled selected>
                      -
                    </option>
                    {item.values.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                ) : item.id === "message" ? (
                  <textarea
                    type="text"
                    id={item.id}
                    name={item.id}
                    value={message}
                    className="w-full py-2 px-3 rounded input"
                    placeholder={item.label}
                    required
                    onChange={handleChange}
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                  />
                ) : (
                  <input
                    type="text"
                    id={item.id}
                    name={item.id}
                    value={
                      item.id === "name"
                        ? name
                        : item.id === "email"
                        ? email
                        : item.id === "companyName"
                        ? companyName
                        : item.id === "phone"
                        ? phone
                        : ""
                    }
                    className="w-full  py-2 px-3 rounded input"
                    placeholder={item.label}
                    required
                    onChange={handleChange}
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                  />
                )}
              </div>
            ))}
            <button
              type="submit"
              className="mr-40 ml-40 sm:mr-72 sm:ml-72 lg:ml-80 lg:mr-80 py-2 px-4 text-white flex items-center
            hover:bg-lightGrey rounded-lg hover:bg-opacity-30 hover:text-primary"
            >
              {t("Contact.btn")}
              {t("language.direction") === "rtl" ? (
                <FaArrowLeft color="yellow" className="ml-2 mx-2" />
              ) : (
                <FaArrowRight color="yellow" className="ml-2 mx-2" />
              )}{" "}
            </button>
            <ToastContainer position="top-center" transition={Zoom} />
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
