import React, { lazy, Suspense } from 'react';
import Nav from '../components/Nav';
import AfaaqHero from '../components/afaaq/afaaqHero';

// Lazy load non-critical components
const AboutAfaaq = lazy(() => import('../components/afaaq/AboutAfaaq'));
const AfaaqPartners = lazy(() => import('../components/afaaq/AfaaqPartners'));
const AfaaqStatistics = lazy(() => import('../components/afaaq/AfaaqStatistics'));
const AfaaqGoals = lazy(() => import('../components/afaaq/AfaaqGoals'));
const AfaaqTracks = lazy(() => import('../components/afaaq/AfaaqTracks'));
const AfaaqImpact = lazy(() => import('../components/afaaq/AfaaqImpact'));
const AfaaqTestimonial = lazy(() => import('../components/afaaq/AfaaqTestimonial'));
const AfaaqContactUs = lazy(() => import('../components/afaaq/afaaqContactUs'));
const Footer = lazy(() => import('../components/Footer'));

// Loading component with skeleton
const SectionLoader = () => (
  <div className="animate-pulse bg-white/5 h-96 w-full" />
);

const Afaaq = () => {
  return (
    <div className="bg-primary min-h-screen">
      {/* Critical components loaded immediately */}
      <Nav />
      <AfaaqHero />
      
      {/* Non-critical components with lazy loading */}
      <Suspense fallback={<SectionLoader />}>
        <main>
          <AboutAfaaq />
          <AfaaqPartners />
          
          <section className="content-defer">
            <AfaaqStatistics />
            <AfaaqGoals />
            <AfaaqTracks />
            <AfaaqImpact />
            <AfaaqTestimonial />
            <AfaaqContactUs />
          </section>
        </main>
      </Suspense>

      {/* Footer with separate suspense boundary */}
      <Suspense fallback={<div className="h-20 bg-white/5" />}>
        <Footer />
      </Suspense>
    </div>
  );
};

// Preload critical components
const preloadComponents = () => {
  // Preload first two sections after hero
  const preloadQueue = [
    () => import('../components/afaaq/AboutAfaaq'),
    () => import('../components/afaaq/AfaaqPartners')
  ];

  requestIdleCallback(() => {
    preloadQueue.forEach(importFn => importFn());
  });
};

// Add to your tailwind.css or index.css
const styles = `
  .content-defer {
    content-visibility: auto;
    contain-intrinsic-size: 0 500px;
  }

  @media (prefers-reduced-motion: reduce) {
    .animate-pulse {
      animation: none;
    }
  }
`;

// Initialize preloading
if (typeof window !== 'undefined') {
  preloadComponents();
}

export default Afaaq;