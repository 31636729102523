import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight, FaFileDownload } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { saveAs } from "file-saver";
import ArabicProfile from "../WetaanCompanyProfile-Ar.pdf";

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [isAOSInitialized, setIsAOSInitialized] = useState(false);

  // Separate AOS initialization from main content render
  useEffect(() => {
    // Defer AOS initialization
    const timer = setTimeout(() => {
      AOS.init({
        once: true,
        disable: "mobile",
        duration: 500, // Reduced duration
        offset: 0, // Reduced offset
        delay: 0, // Remove initial delay
      });
      setIsAOSInitialized(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const downloadProfile = async () => {
    try {
      const response = await fetch(ArabicProfile);
      const blob = await response.blob();
      saveAs(blob, "الملف التعريفي لوتان.pdf");
    } catch (error) {
      console.error("Error downloading profile:", error);
    }
  };

  // Preload critical background image
  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = "../assets/img/bg-img.png";
  }, []);

  return (
    <section
      id="home"
      className="relative min-h-screen w-full bg-[#020055]" // Add fallback color
    >
      {/* Background image with loading optimization */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('../assets/img/bg-img.png')`,
        }}
        role="presentation"
      />

      {/* Content Container - Simplified structure */}
      <div className="relative h-screen flex flex-col justify-center items-center px-4 sm:px-10 md:px-16">
        {/* Main Text - LCP Element */}
        <div className="text-center max-w-4xl mx-auto">
          {/* Priority loading for LCP text */}
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-bold mb-4 mt-5">
            {t("Header.title")}
          </h1>

          {/* Secondary content with delayed animation */}
          {isAOSInitialized && (
            <>
              <h2 className="text-lg sm:text-xl md:text-2xl text-white mb-8 sm:px-8 md:px-16 lg:px-32">
                {t("Header.paragraph")}
              </h2>

              <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-6 mt-8">
                <button
                  onClick={() => {
                    document
                      .getElementById("contact")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                  className="w-full sm:w-auto py-3 px-6 text-primary bg-secondary hover:bg-yellow-300 flex items-center justify-center rounded-lg transition-all duration-300"
                >
                  <span>{t("Header.contactus")}</span>
                  {i18n.language === "ar" ? (
                    <FaArrowLeft className="text-primary mx-2" />
                  ) : (
                    <FaArrowRight className="text-primary mx-2" />
                  )}
                </button>
                <button
                  onClick={downloadProfile}
                  className="w-full sm:w-auto py-3 px-6 text-secondary border-2 border-secondary hover:border-yellow-400 hover:text-yellow-400 flex items-center justify-center rounded-lg transition-all duration-300"
                >
                  <span>{t("Header.profile")}</span>
                  <FaFileDownload className="mx-2" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
