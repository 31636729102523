import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeLangBtn from './ChangeLangBtn';
import { Link as ScrollLink } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';

const NavLink = ({ className = '', children, ...props }) => (
  <motion.div
    className="relative"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 400, damping: 17 }}
  >
    <div {...props} className={`py-2 px-3 group transition-colors duration-200 ${className}`}>
      {children}
      <motion.div
        className="absolute bottom-0 left-0 h-0.5 bg-lightBlue w-0 group-hover:w-full transition-all duration-300"
        whileHover={{ width: "100%" }}
      />
    </div>
  </motion.div>
);

const Nav = () => {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isAfaaqPage = location.pathname === '/afaaq';

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 py-4 transition-all duration-300
      ${scrolled ? 'bg-indigo-950/50 backdrop-blur-sm' : 'bg-transparent'}
      ${isOpen ? 'bg-indigo-950/30 backdrop-blur-md' : ''}`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            <Link to="https://wetaan.com" className="navbar-brand">
              <img src={t('Nav.logo')} className="sm:mr-3 h-6 sm:h-9" alt="Wetaan Logo" />
            </Link>
          </motion.div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-6">
            {isAfaaqPage ? (
              t('Nav.afaaqElements', []).map((item, index) => (
                <NavLink key={index}>
                  <Link to={item.url} className="text-white hover:text-lightBlue">
                    {item.label}
                  </Link>
                </NavLink>
              ))
            ) : (
              t('Nav.elements').map((item, index) => (
                item.url.startsWith('/') ? (
                  <NavLink key={index}>
                    <Link to={item.url} className="text-white hover:text-lightBlue">
                      {item.label}
                    </Link>
                  </NavLink>
                ) : (
                  <NavLink key={index}>
                    <ScrollLink
                      to={item.url.substring(1)}
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="text-white hover:text-lightBlue cursor-pointer"
                      activeClass="active"
                    >
                      {item.label}
                    </ScrollLink>
                  </NavLink>
                )
              ))
            )}
          </div>

          {/* Right Side - Language & Mobile Menu */}
          <div className="flex items-center gap-4">
            <ChangeLangBtn />
            <motion.button
              onClick={() => setIsOpen(!isOpen)}
              className="md:hidden p-2 text-white hover:text-lightBlue transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              aria-label="Toggle menu"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </motion.button>
          </div>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden mt-4 rounded-lg overflow-hidden"
            >
              <motion.div
                className="backdrop-blur-md bg-indigo-950/20 border border-white/10 rounded-lg shadow-lg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="px-4 py-6 flex flex-col gap-4">
                  {isAfaaqPage ? (
                    t('Nav.afaaqElements', []).map((item, index) => (
                      <motion.div
                        key={index}
                        whileHover={{ x: 10 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                      >
                        <Link
                          to={item.url}
                          className="py-2 px-3 block text-white hover:text-lightBlue transition-colors duration-200"
                          onClick={() => setIsOpen(false)}
                        >
                          {item.label}
                        </Link>
                      </motion.div>
                    ))
                  ) : (
                    t('Nav.elements').map((item, index) => (
                      <motion.div
                        key={index}
                        whileHover={{ x: 10 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                      >
                        {item.url.startsWith('/') ? (
                          <Link
                            to={item.url}
                            className="py-2 px-3 block text-white hover:text-lightBlue transition-colors duration-200"
                            onClick={() => setIsOpen(false)}
                          >
                            {item.label}
                          </Link>
                        ) : (
                          <ScrollLink
                            to={item.url.substring(1)}
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="py-2 px-3 block text-white hover:text-lightBlue cursor-pointer"
                            activeClass="active"
                            onClick={() => setIsOpen(false)}
                          >
                            {item.label}
                          </ScrollLink>
                        )}
                      </motion.div>
                    ))
                  )}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Nav;